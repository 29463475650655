<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <MainModal
            :main="{ component: 'DOCUMENT_PERSONAL_TIMESHEET', title: 'Табель обліку робочого часу' }"
            :button="{ icon: true, color: 'grey lighten-4', buttonIcon: 'mdi-plus' }"
            :item="{ date_start: '2021-10-01', date_end: '2021-10-31', month: '2021-10-01' }"
            :full_screen="true"
        />
        <v-menu
            v-model="showMenu"
            :position-x="x"
            :position-y="y"
            absolute
            offset-y
            :close-on-content-click="false"
            allow-overflow
            rounded
        >
          <v-card width="400px">
            <v-card-text>
              <div>Обрані:</div>
              <v-chip-group mandatory style="min-height: 45px; position: relative" class="grey lighten-4 px-2">
                <v-chip
                        v-for="chip in selected_day.values"
                        :key="chip.date"
                        close
                        @click:close="onChipClose(chip)"
                        style="font-size: .8rem; color: #2d2d2d; text-transform: uppercase"
                >
                  {{ `${timesheet_letter_list[chip.timesheet_letter].name}: ${chip.hours}` }}
                </v-chip>
                <v-btn icon absolute style="right: 0; top: 5px" @click="clearAll">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-chip-group>
              <v-divider></v-divider>
              <div class="d-flex mt-2">
                <div style="flex: 1 1 60%">
                  <div>Вид годин</div>
                  <div style="display: flex; flex-wrap: wrap">
                    <div class="chip-custom col mr-1 mb-2 white--text elevation-3"
                         style="padding: 5px 6px !important; cursor: pointer;"
                         v-for="(chip, idx_chip) in timesheet_letter_array"
                         :key="`chip-${idx_chip}`"
                         :style="chip.main ? '' : 'min-width: 27px !important;'"
                         :class="selected_day.current === chip.value ? 'success' : chip.main ? 'grey darken-2' : ' white grey--text text--darken-2'"
                         @click="onChipClick(chip)"
                    >
                      {{ chip.name }}
                    </div>
                  </div>
                </div>
                <div style="flex: 1 1 40%; padding-left: 16px; padding-right: 12px">
                  <div>К-сть годин</div>
                  <div class="hour-input">
                    <input :disabled="!selected_day.current" type="text" v-decimal v-model="selected_day.hours">
                  </div>
                  <div class="mt-4">
                    <v-btn block depressed class="mb-2 success" @click.stop="addToSelectValue">Обрати</v-btn>
                    <v-btn block depressed class="mb-2 secondary" @click.stop="saveSelectValue">Зберегти</v-btn>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-menu>
        <v-card style="overflow: auto">
          <div class="timesheet-header">
            <div class="timesheet-row">
              <div class="timesheet-col" style="flex: 0 0 160px; padding-left: 10px; text-align: left">
                Працівник
              </div>
              <div class="timesheet-col" style="flex: 0 0 60px">
                Всього
              </div>
              <div class="timesheet-col"
                   v-for="(item, idx) in days"
                   :key="`header-col-idx-${idx}`"
                   :style="`flex: 1 1 ${col_width}%; min-width: 32px;`"
                   :class="item.class"
              >
                <div class="day-name">{{ day_names[item.day_of_week] }}</div>
                <div class="day-number">{{ item.day }}</div>
              </div>
            </div>
          </div>
          <div class="timesheet-body">
            <div class="timesheet-row"
                 v-for="(person, idx) in persons"
                 :key="`person-${person.id}_${idx}`"
            >
              <div class="timesheet-col" style="flex: 0 0 160px; padding-left: 10px">
                {{ person.full_name }}
              </div>
              <div class="timesheet-col" style="flex: 0 0 60px">

              </div>
              <div class="timesheet-col"
                   v-for="(col, idx_col) in person.days"
                   :key="`person-${person.id}_${idx}-${idx_col}`"
                   :style="`flex: 1 1 ${col_width}%; min-width: 32px; padding: 3px; cursor: pointer`"
                   @click="onItemClick($event, person.id, col.date)"
              >
                <div
                    v-for="(col_value, idx_value) in col.values"
                    :key="`person-${person.id}_${idx}-${idx_col}-${idx_value}`"
                    style="margin-top: 1px; margin-bottom: 2px;"
                >
                  <div class="chip-custom">
                    {{ `${timesheet_letter_list[col_value.timesheet_letter].name} ${col_value.hours}` }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {getDateFromString} from "@/utils/icons";
import {timesheet_letter_array, timesheet_letter_list} from "@/utils/accounting"

export default {
  name: "personal_timesheet",
  components: {
    MainModal: () => import("@/components/modal/MainModal"),
  },
  data() {
    return {
      timesheet_letter_list,
      timesheet_letter_array,
      showMenu: false,
      x: 0,
      y: 0,
      selected_day: {},
      date_start: '2021-10-01',
      date_end: '2021-10-31',
      month: '2021-10-01',
      fill_type: 'by_full_month',
      items: [
        {
          person_id: 1,
          full_name: 'Буняк Володимир Сергійович',
          date: '2021-10-01',
          timesheet_letter: 'work',
          hours: 8,
          timesheet_letter_short: 'р'
        },
        {
          person_id: 1,
          full_name: 'Буняк Володимир Сергійович',
          date: '2021-10-01',
          timesheet_letter: 'non_appearance_by_col_contract',
          hours: 2,
          timesheet_letter_short: 'вч'
        },
        {
          person_id: 2,
          full_name: 'Буняк Катерина Миколаївна',
          date: '2021-10-04',
          timesheet_letter: 'work',
          hours: 8,
          timesheet_letter_short: 'р'
        },
        {
          person_id: 2,
          full_name: 'Буняк Катерина Миколаївна',
          date: '2021-10-04',
          timesheet_letter: 'non_appearance_by_col_contract',
          hours: 2,
          timesheet_letter_short: 'вч'
        },
      ],
      persons: [
        {id: 1, full_name: 'Буняк Володимир Сергійович'},
        {id: 2, full_name: 'Буняк Катерина Миколаївна'},
      ],
      days: [],
      days_array: [],
      day_names: {
        1: 'Пн',
        2: 'Вт',
        3: 'Ср',
        4: 'Чт',
        5: 'Пт',
        6: 'Сб',
        7: 'Нд',
      }
    }
  },
  computed: {
    col_width() {
      return (100 / this.days.length).toPrecision(4)
    }
  },
  methods: {
    clearAll() {
      this.selected_day.values = []
    },
    addToSelectValue() {
      const item = this.selected_day.values.find(i => i.timesheet_letter === this.selected_day.current)

      if (!item) {
        this.selected_day.values.push(
            {timesheet_letter: this.selected_day.current, hours: this.selected_day.hours}
        )
      } else {
        item.hours = this.selected_day.hours
      }
      this.selected_day.hours = 0
      this.selected_day.current = ''
    },
    saveSelectValue() {
      const person = this.persons.find(p => p.id === this.selected_day.person_id)
      if (person) {
        const c = person.days.find(d => d.date === this.selected_day.date)
        if (c) {
          c.values = this.selected_day.values
        }
      }
      this.selected_day = {date: null, current: '', hours: 0, person_id: null, values: []}
      this.showMenu = false
    },
    onChipClick(payload) {
      this.$nextTick(() => {
        this.selected_day.current = payload.value
      })
    },
    onChipClose(payload) {
      const delete_idx = this.selected_day.values.indexOf(payload)
      this.selected_day.values.splice(delete_idx, 1)
    },
    onItemClick(e, person_id, date) {
      this.showMenu = false
      const person = this.persons.find(p => p.id === person_id)
      this.selected_day = {date, current: '', hours: 0, person_id}
      if (person) {
        const c = person.days.find(d => d.date === date)
        if (c) {
          this.selected_day = {date, values: c.values.map(i => Object.assign({}, i)), current: '', hours: 0, person_id}
        }
      }
      if (!this.selected_day.values) {
        this.selected_day.values = []
      }
      this.x = e.clientX
      this.y = e.clientY
      this.$nextTick(() => {
        this.showMenu = true
      })
    },
    buildCols() {
      const date_start_js = getDateFromString(this.date_start)
      const date_end_js = getDateFromString(this.date_end)
      const year = date_start_js.getFullYear()
      const month = date_start_js.getMonth() + 1

      for (let day = date_start_js.getDate(); day <= date_end_js.getDate(); day++) {
        let day_of_week_ukr = 0

        const day_of_week = new Date(year, month - 1, day).getDay()

        if (day_of_week === 0) {
          day_of_week_ukr = 7
        } else {
          day_of_week_ukr = day_of_week
        }

        const date = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`

        let day_type = ''
        let class_type = ''


        if (day_of_week_ukr <= 5) {
          day_type = 'work'
          class_type = 'work'
        } else if (day_of_week_ukr === 6) {
          day_type = 'opening'
          class_type = 'opening-1'
        } else if (day_of_week_ukr === 7) {
          day_type = 'opening'
          class_type = 'opening-2'
        }
        this.days.push(
            {
              day: day,
              date: date,
              type: day_type,
              day_of_week: day_of_week_ukr,
              class: class_type,
            }
        )
        this.days_array.push(day)
      }
    },
    buildPersonData() {
      const persons = {}

      this.items.forEach(i => {
        if (i.person_id in persons) {
          const f = persons[i.person_id].days.find(d => d.date === i.date)
          if (f) {
            const v = f.values.find(c => c.timesheet_letter === i.timesheet_letter)
            if (!v) {
              f.values.push(
                  {timesheet_letter: i.timesheet_letter, hours: i.hours}
              )
            }
          }
        } else {
          const days = this.days.map(a => {
            return {date: a.date, values: []}
          })
          const d = days.find(f => f.date === i.date)
          if (d) {
            d.values.push({timesheet_letter: i.timesheet_letter, hours: i.hours})
          }
          persons[i.person_id] = {id: i.person_id, full_name: i.full_name, days: days}
        }
      })
      this.persons = Object.keys(persons).map(i => {
        const value = persons[i]
        return Object.assign({}, value)
      })
    }
  },
  created() {
    this.buildCols()
    this.buildPersonData()
  }
}
</script>

<style scoped lang="scss">
.timesheet-row {
  display: flex;

  .timesheet-col {
    padding: 2px;

  }
}

.timesheet-header {
  font-size: .82rem;

  .timesheet-row {
    .timesheet-col {
      border-right: .5px solid #e8e8e8;
      background-color: #f5f5f5;
      text-align: center;

      .day-name {
        font-size: .78rem;
      }

      .day-number {
        font-size: .8rem;
      }
    }

    .timesheet-col:last-child {
      border-right: none !important;
    }
  }
}

.timesheet-body {
  font-size: .8rem;

  .timesheet-row {

    .timesheet-col {
      border-bottom: .5px solid #e8e8e8;
      border-right: .5px solid #e8e8e8;
    }

    .timesheet-col:last-child {
      border-right: none !important;
    }
  }
}

.chip-custom {
  width: 100%;
  height: 100%;
  background-color: #dedede;
  font-size: .76rem;
  text-align: center;
  padding: 1px 2px;
  border-radius: 4px;
  cursor: pointer;
}

.hour-input {
  input {
    width: 100%;
    border: none;
    outline: 0;
    font-size: 3rem;
    text-align: center;
    padding: 10px;
    background: #e7e7e7;
  }
}
</style>